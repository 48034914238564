
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, computed } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import { SelectModel } from "@/core/models/SelectModel"
import { Transfer } from "@/core/models/Transfer"

import { FormInstance } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import swalConfirm from "@/core/helpers/swal-confirm"
import { CashBookListItem } from "@/core/models/CashBookListItem"
import { currencyForm } from "@/core/helpers/currencyformat"

export default defineComponent({
  name: "branch-virman",
  components: {},
  setup() {
    const isTableDataLoading = ref(false)
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isFromCashBookSelectLoading = ref(false)
    const isToCashBookSelectLoading = ref(false)

    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])
    const fromCashBookList = ref<CashBookListItem[]>([])
    const toCashBookList = ref<CashBookListItem[]>([])

    const transferData = ref<Transfer>({} as Transfer)

    const activeBusinessId = ref<string>()
    const activeFromBranchId = ref<string | undefined>()
    const activeToBranchId = ref<string | undefined>()
    const ruleFormRef = ref<FormInstance>()

    const rules = ref({
      fromCashBookId: getRule(RuleTypes.SELECT, "Hesap"),
      toCashBookId: getRule(RuleTypes.SELECT, "Hesap"),
      amount: getRule(RuleTypes.NUMBER, "Tutar"),
      description: getRule(RuleTypes.TEXT100, "Açıklama"),
    })

    onMounted(async () => {
      await getBusinessList()
    })

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      isBusinessSelectLoading.value = false
    }

    const getCashBookList = async (branchId, isFrom) => {
      if (isFrom) {
        isFromCashBookSelectLoading.value = true
        const { data } = await ApiService.get("cash-book/branch/" + branchId)
        isFromCashBookSelectLoading.value = false
        fromCashBookList.value = data.map(x => ({ id: x.id, name: x.name, balance: x.balance }))
      } else {
        isToCashBookSelectLoading.value = true
        const { data } = await ApiService.get("cash-book/branch/" + branchId)
        isToCashBookSelectLoading.value = false
        toCashBookList.value = data.map(x => ({ id: x.id, name: x.name, balance: x.balance }))
      }
    }

    function addTransfer(formEl: FormInstance | undefined) {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          const confirmResult = await swalConfirm()
          if (confirmResult.isConfirmed) {
            const data = {
              ...transferData.value,
            }
            await ApiService.post("cash-book/transfer", data)
            activeBusinessId.value = undefined
            activeFromBranchId.value = undefined
            activeToBranchId.value = undefined
            formEl.resetFields()
          }
        }
      })
    }

    const OnChangeBusiness = async businessId => {
      transferData.value.fromCashBookId = undefined
      transferData.value.toCashBookId = undefined
      activeFromBranchId.value = undefined
      activeToBranchId.value = undefined
      await getBranchList(businessId)
    }

    const OnChangeBranch = async (branchId, isFrom) => {
      if (isFrom) transferData.value.fromCashBookId = undefined
      else transferData.value.toCashBookId = undefined
      await getCashBookList(branchId, isFrom)
    }

    const getBranchList = async businessId => {
      if (businessId != undefined) {
        isBranchSelectLoading.value = true
        const config = {
          params: {
            businessId: businessId,
          },
        }
        const { data } = await ApiService.query("branch", config)
        branchList.value = data.map(x => ({ id: x.id, name: x.title }))
        isBranchSelectLoading.value = false
      }
    }

    const getFromCashbookAccountBalance = computed(() => {
      const cashBookAccount = fromCashBookList.value.find(
        x => x.id == transferData.value.fromCashBookId
      )
      return cashBookAccount?.balance
    })

    const getToCashbookAccountBalance = computed(() => {
      const cashBookAccount = toCashBookList.value.find(
        x => x.id == transferData.value.toCashBookId
      )
      return cashBookAccount?.balance
    })

    return {
      dateForm,
      DateFormatTypes,
      isTableDataLoading,
      isFromCashBookSelectLoading,
      isToCashBookSelectLoading,
      fromCashBookList,
      toCashBookList,
      getFromCashbookAccountBalance,
      getToCashbookAccountBalance,
      businessList,
      branchList,
      activeBusinessId,
      activeFromBranchId,
      activeToBranchId,
      Edit,
      getCashBookList,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      OnChangeBusiness,
      OnChangeBranch,
      ruleFormRef,
      rules,
      transferData,
      addTransfer,
      currencyForm,
    }
  },
})
